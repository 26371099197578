.container{
    margin-top: 10rem;
}
.carousel-img img {
    max-width: 90%; /* Adjust this value as needed */
    height: auto;
}

.carousel-item{
    margin-top: 20rem;
    display: inline-block;
    margin: 10px 10px; /* Adjust margin as needed for spacing between images */
    position: relative;
}

.carousel-hover-content {
    position: absolute;
    
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(17, 203, 216, 0.8);
    color: white;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.carousel-item:hover .carousel-hover-content {
    opacity: 1;
}

.carousel-button {
    margin-top: 20px; /* Adjust margin as needed */
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
}

.carousel-button:hover {
    background-color: #2980b9;
}

/* QuoteCarousel.css */
.carousel-container {
    position: relative;
    text-align: center;
    
}

.carousel {
    position: relative;
    margin-bottom: 20px; /* Adjust margin as needed */
}

.carousel-arrow {
    position: absolute;
    top: 27%;
    transform: translateY(-50%);
    z-index: 1;
    background: none;
    border: none;
    color: #3498db;
    cursor: pointer;
    font-size: 20px;
}

.carousel-prev {
    left: 0;
}

.carousel-next {
    right: 0;
}

.carousel-button {
    margin-top: 20px; /* Adjust margin as needed */
    padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
}

.carousel-button:hover {
    background-color: #2980b9;
}

.carousel-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


