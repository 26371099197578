
.dropbtn {
    background-color: transparent;
    color: rgb(19, 18, 18);
    padding: 16px;
    font-size: 16px;
    border: none;
    gap: 120px;
    border-radius: 25px;
  }
  
  
  .dropdown {
    position: relative;
    display: inline-block;
    /* padding: 10px; */
  
  }
  
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding-top: 10px;
    border-radius: 10px;
  }

  
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  