

.video-container {
    position:relative;
    display:flex;
    padding-bottom: 56.25%;
  
  }
  
  #video-player {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .responsive-video-container{
    max-width:100%;
    position: relative;
    width: 200%;
    height:120%;
    
    overflow:hidden;
    /* padding-bottom: 56.25%; */
    /* margin-bottom: 2rem; */
   
  }